<template>
  <div class="article-detail" :id="'article-detail' + index">
    <div class="article-detail-inner">
      <div class="article-detail-not-clicked" @click="is_clicked = true">
        <div class="img-filter">
          <img
            :src="article.thumbnail_url"
            class="bg-image"
            :alt="'Gallary画像' + index"
            @load="is_loaded = true"
            v-show="is_loaded"
          />
        </div>
        <img src="/assets/images/loading.gif" v-show="!is_loaded" />
        <div class="article-content" v-show="is_loaded">
          <h4 class="article-title">{{ article.title }}</h4>
          <h6 class="article-posted_date">{{ article.posted_date }}</h6>
          <p class="article-content-first-100">{{ article_content }}</p>
        </div>
      </div>
      <!-- pc tabletのみ -->
      <div
        class="article-detail-clicked"
        v-show="is_clicked"
        :id="'article-detail-clicked' + index"
        v-if="!is_mobile"
      >
        <div
          class="article-detail-clicked-inner"
          :id="'article-detail-clicked-inner' + index"
        >
          <span class="close" @click="is_clicked = false">
            <span></span>
          </span>
          <div class="article-detail-clicked-right-left">
            <div class="article-detail-clicked-left">
              <img
                :src="article.thumbnail_url"
                class="bg-image"
                :alt="'Gallary画像' + index"
                @load="is_loaded = true"
                v-show="is_loaded"
              />
            </div>
            <div class="article-detail-clicked-right">
              <div class="title-and-posted_date">
                <h1 class="article-clicked-title">{{ article.title }}</h1>
                <h6 class="article-clicked-posted_date">
                  {{ article.posted_date }}
                </h6>
              </div>
              <p class="article-clicked-content">{{ article.content }}</p>
            </div>
          </div>
        </div>
      </div>
      <!-- pc tabletのみ -->
    </div>
  </div>
</template>
<script>
export default {
  props: {
    article: Object,
    index: Number,
  },
  data() {
    return {
      before_changed_window_width: window.innerWidth,
      window_width: window.innerWidth,
      is_loaded: false,
      is_clicked: false,
    };
  },
  computed: {
    article_content() {
      let max_char_size = this.window_width > 1024 ? 100 : 30;
      if (this.window_width <= 1024 && this.article.title.length > 15)
        max_char_size = 0;
      let content = this.article.content.slice(0, max_char_size);
      if (this.article.content.length > max_char_size + 1) {
        if (max_char_size > 0) content = content + "...";
      }
      return content;
    },
    height() {
      return {
        "--height": window.innerHeight,
      };
    },
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      this.window_width = window.innerWidth;
      if (this.before_changed_window_width !== this.window_width)
        this.is_clicked = false;
      this.before_changed_window_width = this.window_width;
    },
  },
  watch: {
    is_clicked(val) {
      if (val) {
        document.getElementById(
          "article-detail-clicked" + this.index
        ).style.height = document.documentElement.offsetHeight + "px";
        document.getElementById(
          "article-detail-clicked-inner" + this.index
        ).style.top =
          document
            .getElementById("article-detail-clicked-inner" + this.index)
            .getBoundingClientRect().top +
          window.pageYOffset +
          "px";
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/stylesheets/Gallary/gallary-detail-pc.scss";
@import "@/assets/stylesheets/Gallary/gallary-detail-tab.scss";
@import "@/assets/stylesheets/Gallary/gallary-detail-mobile.scss";
</style>
